import OptOutForm from "../components/OptOutForm"
import "./home.scss"

const Home = () => {
    
    return (
        <div className="home">
            <div className="form-container">
                <OptOutForm />
            </div>
        </div>
    )
}

export default Home