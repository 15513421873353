import { useEffect, useState, useRef } from "react"
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from "reactstrap"
import fdlogo from "../assets/fanduel_lg_hrz_1cp_wht_rev.svg"
import successcheck from "../assets/check-circle-fill.svg"
import UsStates from "../helpers/helpers"
import ReCAPTCHA from "react-google-recaptcha";

import classNames from 'classnames'

const OptOutForm = () => {
    const recaptcha = useRef();
    const [firstName, setFirstName] = useState('')
    // eslint-disable-next-line
    const [firstNameValid, setFirstNameValid] = useState()
    const [firstNameFocused, setFirstNameFocused] = useState(false)
    const [firstNameEdited, setFirstNameEdited] = useState(false)

    const [lastName, setLastName] = useState('')
    // eslint-disable-next-line
    const [lastNameValid, setLastNameValid] = useState()
    const [lastNameFocused, setLastNameFocused] = useState(false)
    const [lastNameEdited, setLastNameEdited] = useState(false)

    const [address1, setAddress1] = useState('')
    // eslint-disable-next-line
    const [address1Valid, setAddress1Valid] = useState()
    const [address1Focused, setAddress1Focused] = useState(false)
    const [address1Edited, setAddress1Edited] = useState(false)

    const [address2, setAddress2] = useState('')
    const [address2Focused, setAddress2Focused] = useState(false)
    const [city, setCity] = useState('')
    // eslint-disable-next-line
    const [cityValid, setCityValid] = useState()
    const [cityFocused, setCityFocused] = useState(false)
    const [cityEdited, setCityEdited] = useState(false)

    const [state, setState] = useState('')
    const [stateName, setStateName] = useState('')
    // eslint-disable-next-line
    const [stateValid, setStateValid] = useState(true)
    const [zip, setZip] = useState('')
    // eslint-disable-next-line
    const [zipValid, setZipValid] = useState()
    const [zipFocused, setZipFocused] = useState(false)
    const [zipEdited, setZipEdited] = useState(false)

    const [error, setError] = useState(null)
    const [statesDropDownIsOpen, setStatesDropdownIsOpen] = useState(false)
    const [formValid, setFormValid] = useState(false)
    const [formEdited, setFormEdited] = useState(false)
    const [captchaValid, setCaptchaValid] = useState(false)
    const [captchaToken, setCaptchaToken] = useState('')
    const [success, setSuccess] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const NAME_REGEX = /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/


    const onHandleOptOutSubmit = async (e) => {
        e.preventDefault()

        const validForm = validateForm();
        if (!validForm) {
            setEditedState(true)
            return;
        }

        if (!validForm || !captchaValid) { return; }
        
        setFormValid(false);
        setCaptchaValid(false);

        const optout = {
            firstName,
            lastName,
            address1,
            address2,
            city,
            state,
            zip,
            captchaToken
        }
        
        const response = await fetch('/api/optouts', {
            method: 'POST',
            body: JSON.stringify(optout),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setError(json.error)
        }
        if (response.ok) {
            setFirstName('')
            setLastName('')
            setAddress1('')
            setAddress2('')
            setCity('')
            setState('')
            setZip('')
            setError(null);
            setSuccess(true);
            setCaptchaToken(null)
            setCaptchaValid(false)
        }
    }

    const setEditedState = (edited) => {
        setFirstNameEdited(edited)
        setLastNameEdited(edited)
        setAddress1Edited(edited)
        setCityEdited(edited)
        setZipEdited(edited)
    }

    const validateNames = (name) => {
        let valid = false;
        valid = NAME_REGEX.test(name);
        return valid;
    }

    const validateForm = () => {
        if (
            (!firstNameValid ||
            !lastNameValid ||
            !address1Valid ||
            !cityValid ||
            !stateValid ||
            !zipValid ||
            !captchaValid) 
            && formEdited
        ) {
            setFormValid(false);
            return false;
        } else {
            setFormValid(true);
            return true;
        }
    }


    const validateCaptcha = (e) => {
        if (!state) { return; }
        
        const validate = async () => {
            const captchaValue = recaptcha.current.getValue();
    
            if (!captchaValue) {
                setCaptchaValid(false)
            } else {
                setCaptchaValid(true);
                setCaptchaToken(captchaValue);
            }
        }
        validate()
    }

     const invalidateCaptcha = () => {
        setCaptchaToken(null)
        setCaptchaValid(false)
    }

    useEffect(() => { 
        if (!loaded) { return; }
        const valid = validateNames(firstName) && firstName.length > 1;
        setFirstNameValid(valid);
        setFirstNameEdited(true);
        // eslint-disable-next-line
    }, [firstName])

    useEffect(() => { 
        if (!loaded) { return; }
        const valid = validateNames(lastName) && lastName.length > 1;
        setLastNameValid(valid);
        setLastNameEdited(true)
        // eslint-disable-next-line
    }, [lastName])
    
    useEffect(() => { 
        if (!loaded) { return; }
        const valid = address1.length > 5;
        setAddress1Valid(valid);
        setAddress1Edited(true);
        // eslint-disable-next-line
    }, [address1])
    
    useEffect(() => { 
        if (!loaded) { return; }
        const valid = city.length > 1;
        setCityValid(valid);
        setCityEdited(true);
        // eslint-disable-next-line
    }, [city])
    
    useEffect(() => { 
        if (!loaded) { return; }
        const valid = zip.length === 5;
        setZipValid(valid);
        setZipEdited(true);
        // eslint-disable-next-line
    }, [zip])

    const handleStateChange = (e) => {
        setState(e.target.value);
        setStateName(e.target.textContent);
   }

    const toggleStatesDropDown = (e) => {
        setStatesDropdownIsOpen(!statesDropDownIsOpen);
    }
    
    const toggleActiveFocus = (e) => {
        const field = e.target.name;
        switch (field) {
            case "firstName":
                setFirstNameFocused(true);
                setLastNameFocused(false);
                setAddress1Focused(false);
                setAddress2Focused(false);
                setCityFocused(false);
                setZipFocused(false);
                break;
            case "lastName":
                setLastNameFocused(true);
                setFirstNameFocused(false);
                setAddress1Focused(false);
                setAddress2Focused(false);
                setCityFocused(false);
                setZipFocused(false);
                break;
            case "address1":
                setAddress1Focused(!address1Focused);
                setLastNameFocused(false);
                setFirstNameFocused(false);
                setAddress2Focused(false);
                setCityFocused(false);
                setZipFocused(false);
                break;
            case "address2":
                setAddress2Focused(!address2Focused);
                setLastNameFocused(false);
                setAddress1Focused(false);
                setFirstNameFocused(false);
                setCityFocused(false);
                setZipFocused(false);
                break;
            case "city":
                setCityFocused(!cityFocused);
                setLastNameFocused(false);
                setAddress1Focused(false);
                setAddress2Focused(false);
                setFirstNameFocused(false);
                setZipFocused(false);
                break;
            case "zip":
                setZipFocused(!zipFocused);
                setLastNameFocused(false);
                setAddress1Focused(false);
                setAddress2Focused(false);
                setCityFocused(false);
                setFirstNameFocused(false);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setFormEdited(true);
        validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        firstName,
        firstNameValid,
        lastName,
        lastNameValid,
        address1,
        address1Valid,
        address2,
        city,
        cityValid,
        state,
        zip,
        zipValid,
        captchaValid,
    ]);

    useEffect(() => { 
        setLoaded(true)
    }, [])

    return (
        <>
            <div className="form-wrap">
                <header className="logo-header">
                    <img alt="" className="fd-logo" src={fdlogo}></img>
                </header>
                <Card>
                    {!success &&
                        <CardHeader>
                            <h3>Opt-Out Form</h3>
                        </CardHeader>
                    }
                    <CardBody>
                        {!success && !error &&
                        <Form className="opt-out-form" onSubmit={onHandleOptOutSubmit}>
                                <div className="fd-info">
                                        <b>Welcome to our Direct Mail Preferences Form</b>

<p>
At FanDuel, we value your privacy and want to ensure that you have full control over the communications you receive from us. If you have received direct mailers from us and wish to manage your preferences, you've come to the right place.</p>

<b>What Can You Do Here?</b>

<ul>
Opt-Out of Direct Mail: If you'd like to stop receiving our marketing mailers, simply select this option, and we'll make sure to respect your choice.
</ul>
<p>Please provide the necessary details in the webform to help us locate your information and process your preferences accurately. We are committed to protecting your personal information and ensuring that your choices are honored.</p>
<p>Your trust in us is important, and we appreciate your continued support. If you have any questions or need assistance, don't hesitate to reach out to our Customer Support team at <a href="https://support.fanduel.com/s/contactsupport">https://support.fanduel.com/s/contactsupport</a></p>

<p>Thank you for helping us tailor our communications to your preferences.
                                        </p>
                                    </div>
                            <FormGroup className={classNames("fd-input dd", { "invalid": !stateValid } )}>
                                <Label>State<sup>*</sup></Label>
                                    <Dropdown
                                        isOpen={statesDropDownIsOpen}
                                        toggle={toggleStatesDropDown}
                                    >
                                    <DropdownToggle caret block outline color="primary" size="md">{stateName || "Your State of Residence"}</DropdownToggle>
                                    <DropdownMenu>
                                        {UsStates().map((item, index) => { 
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    value={item.abbreviation}
                                                    title={item.name}
                                                    onClick={handleStateChange}
                                                >
                                                    {item.name}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                                <FormFeedback>
                                    
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className={classNames("fd-input", { "active": firstNameFocused, "invalid": !firstNameValid && firstNameEdited })}>
                                <Label>First Name<sup>*</sup></Label>
                                <Input
                                    type="text"
                                    name="firstName"
                                    onFocus={toggleActiveFocus}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={firstName}
                                    invalid={!firstNameValid && firstNameEdited}
                                        />
                                <FormFeedback invalid>
                                    Invalid or too few characters.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className={classNames("fd-input", {"active": lastNameFocused, "invalid": !lastNameValid && lastNameEdited })}>
                                <Label>Last Name<sup>*</sup></Label>
                                <Input
                                    type="text"
                                    name="lastName"
                                    onFocus={toggleActiveFocus}
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                    invalid={!lastNameValid && lastNameEdited}
                                    />
                                <FormFeedback invalid>
                                    Invalid or too few characters.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className={classNames("fd-input", {"active": address1Focused, "invalid": !address1Valid && address1Edited })}>
                                <Label>Street Address<sup>*</sup></Label>
                                <Input
                                    type="text"
                                    name="address1"
                                    onFocus={toggleActiveFocus}
                                    onChange={(e) => setAddress1(e.target.value)}
                                    value={address1}
                                    invalid={!address1Valid && address1Edited}
                                    />
                                <FormFeedback invalid>
                                    Invalid or too few characters.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className={classNames("fd-input", {"active": address2Focused})}>
                                <Label>Apartment/Suite</Label>
                                <Input
                                    type="text"
                                    name="address2"
                                    onFocus={toggleActiveFocus}
                                    onChange={(e) => setAddress2(e.target.value)}
                                    value={address2}
                                    />
                                <FormFeedback>
                                    
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className={classNames("fd-input", {"active": cityFocused, "invalid": !cityValid && cityEdited })}>
                                <Label>City<sup>*</sup></Label>
                                <Input
                                    type="text"
                                    name="city"
                                    onFocus={toggleActiveFocus}
                                    onChange={(e) => setCity(e.target.value)}
                                    value={city}
                                    invalid={!cityValid && cityEdited}
                                    />
                               <FormFeedback invalid>
                                    Invalid or too few characters.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className={classNames("fd-input", {"active": zipFocused, "invalid": !zipValid && zipEdited })}>
                                <Label>Zip Code<sup>*</sup></Label>
                                <Input
                                    type="number"
                                    name="zip"
                                    onFocus={toggleActiveFocus}
                                    onChange={(e) => setZip(e.target.value)}
                                    value={zip}
                                    invalid={!zipValid && zipEdited}
                                /> 
                                <FormFeedback invalid>
                                    Needs to be 5 digits.
                                </FormFeedback>
                                    </FormGroup>
                                    {state &&
                                    <>
                            <FormGroup className="captcha">
                                <ReCAPTCHA
                                    ref={recaptcha}
                                    onChange={validateCaptcha}
                                    onExpired={invalidateCaptcha}
                                    sitekey={"__RECAPTCHA_SITE_KEY__"}
                                        />
                                {formValid && !captchaValid &&
                                    <FormFeedback>
                                        Please check the box.
                                    </FormFeedback>
                                }
                            </FormGroup>
                            <FormGroup>   
                                <Button
                                    block
                                    color="primary"
                                    className="fd"
                                    type="submit"
                                    disabled={!formValid}
                                >
                                    Opt Out
                                </Button>
                            </FormGroup>
                            </>
                        }
                    </Form>
                        }
                        {success && 
                            <>
                            <div className="success">
                                <img alt="" src={successcheck}></img>
                                <h3>Your opt out request has been submitted.</h3>
                            </div>
                            </>
                        }
                        {error && 
                            <>
                            <div className="error">
                                <h3>Sorry, there has been an error submitting your opt out request. Please try again.</h3>
                                <a href="/">Back</a>
                            </div>
                        </>
                        }
                    </CardBody>
                </Card>
            </div>
        </>
    )
}
export default OptOutForm
